import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import { GoogleMap, LoadScript, Polygon, Marker, DrawingManagerF } from '@react-google-maps/api';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    margin: 5
  },
  sidebarContainer: {
    width: '20%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    height: '100%',
    backgroundColor: '#ddd',
  },
  mapContainer: {
    width: '80%',
    height: '100vh'
  },
  sideBarDetails: {
    // backgroundColor: 'pink',
    // width: '100%',
    // padding: theme.spacing(2),
    // boxSizing: 'border-box',
    // height: '100%',
  },
  sidebarItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    marginBottom: 10,
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1),
    borderRadius: 5,
    border: '2px solid transparent' // Default border
  },
  selectedSidebarItem: {
    border: '2px solid blue' // Highlight border for selected item
  },
  itemColorBlock: {
    height: 50,
    width: 50,
    borderRadius: 5,
    backgroundColor: '#ff2200',
    border: '2px solid #111'
  },
  saveArea: {
    marginTop: 50,
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center'
  }
}))

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultCenter = {
  lat: 33.91023013848473,
  lng: -112.084847523098
};

const getPolygonArea = (paths) => {
  if (window.google && window.google.maps && window.google.maps.geometry) {
    const latLngPaths = paths.map(path => new window.google.maps.LatLng(path.lat, path.lng));
    const areaMeters = window.google.maps.geometry.spherical.computeArea(latLngPaths);
    const areaFeet = areaMeters * 10.7639; // Convert square meters to square feet
    return { areaMeters, areaFeet };
  }
  return { areaMeters: 0, areaFeet: 0 }; // Default area if google maps is not available
};

const MapWithDrawing = (props) => {
  // console.log('Props', props)
  const styles = useStyles()
  const [loading, setLoading] = useState(false)
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [newArea, setNewArea] = useState({ name: '', strokeColor: '#FF0011', fillColor: '#FF1100', paths: [] });
  const [isDrawing, setIsDrawing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [zoom, setZoom] = useState(19);
  const [jobSite, setJobSite] = useState({})

  const drawingManagerRef = useRef(null);
  const mapRef = useRef(null);
  
  useEffect(() => {
    let isMounted = true

    const fetchJobSiteAreas = async (siteId) => {
      // console.log('Fetch site areas', siteId)
      setLoading(true)
      const response = await axios.get(`${props.url}/api/customer/fetchJobSiteAreas?id=${siteId}`)
      // console.log('Invoices!!', response)
      setLoading(false)
      if (response && response.data && response.data._id) {
        // console.log('Response areas', response.data)
        if (isMounted) {
          setJobSite(response.data)
          if (response.data && response.data.areas && response.data.areas.length) setPolygons(response.data.areas)
        }
      }
    }
    // const savedPolygons = JSON.parse(localStorage.getItem('polygons'));
    // if (savedPolygons) {
    //   // setPolygons(savedPolygons);
    // }
    if (props?.match?.params?.jobsiteId) fetchJobSiteAreas(props?.match?.params?.jobsiteId)
    // if (props?.location?.state?.jobSite?._id) {
    //   setJobSite(props.location.state.jobSite)
    // }

    return () => {
      isMounted = false
    }
  }, []);

  const onPolygonComplete = (polygon) => {
    const paths = polygon.getPath().getArray().map(latlng => ({ lat: latlng.lat(), lng: latlng.lng() }));
    setNewArea({ ...newArea, paths });
    setIsDialogOpen(true);
    polygon.setMap(null); // Remove the polygon from the map until confirmed
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsDrawing(false);
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  const handleSaveArea = () => {
    const newPolygons = [...polygons, newArea];
    setPolygons(newPolygons);
    // localStorage.setItem('polygons', JSON.stringify(newPolygons));
    setIsDialogOpen(false);
    setNewArea({ name: '', strokeColor: '#FF0000', fillColor: '#FF0000', paths: [] });
    setIsDrawing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewArea({ ...newArea, [name]: value });
  };

  const handleSidebarClick = (index) => {
    handlePolygonClick(index)
  }

  const handlePolygonClick = (index) => {
    setSelectedPolygon(index);
    const polygon = polygons[index];
    setNewArea({ name: polygon.name, strokeColor: polygon.strokeColor, fillColor: polygon.fillColor, paths: polygon.paths });
    setIsDialogOpen(true);
  };

  const handleDeletePolygon = () => {
    const msg = `Are you sure you wish to delete this area?`
    if (window.confirm(msg)) {
      const newPolygons = polygons.filter((_, index) => index !== selectedPolygon);
      setPolygons(newPolygons);
      // localStorage.setItem('polygons', JSON.stringify(newPolygons));
      setSelectedPolygon(null);
      setIsDialogOpen(false);
    }
  };

  const handleUpdatePolygon = () => {
    const updatedPolygons = polygons.map((polygon, index) =>
      index === selectedPolygon ? newArea : polygon
    );
    setPolygons(updatedPolygons);
    // localStorage.setItem('polygons', JSON.stringify(updatedPolygons));
    setSelectedPolygon(null);
    setIsDialogOpen(false);
  };

  const startDrawingPolygon = () => {
    setIsDrawing(true);
    // if (drawingManagerRef.current) {
    //   drawingManagerRef.current.state.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    // }
  };

  const getPolygonCenter = useCallback((paths) => {
    if (window.google && window.google.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      paths.forEach(path => {
        bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
      });
      return bounds.getCenter();
    }
    return center; // Default center if google maps is not available
  }, []);

  const createLabelIcon = (text) => {
    if (window.google && window.google.maps) {
      const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="30">
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="16" fill="black">${text}</text>
        </svg>`;
      return {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
        scaledSize: new window.google.maps.Size(200, 30),
        anchor: new window.google.maps.Point(100, 15)
      };
    }
  };

  const handleSaveAreas = () => {
    // console.log('Time to save these areas!!!', polygons)
    let polygonsWithAreas = polygons.map(item => {
      const area = getPolygonArea(item.paths)
      return {
        ...item,
        area: area.areaFeet.toFixed(2)
      }
    })
    let jobSite = null
    if (props?.location?.state?.jobSite?._id) {
      jobSite = props.location.state.jobSite
    }
    setLoading(true)
    // console.log('handle save', state)
    // console.log(props)
    let obj = {
      areas: polygonsWithAreas,
      jobSite
    }
    console.log(obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/updateJobSite?areasOnly=1`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to create new Job Site: ', response)
      setLoading(false)
    }).catch(e => {
      console.log('Error saving our areas....')
    })
  }

  const libs = ['drawing', 'geometry']
  let center = defaultCenter
  // if (props?.location?.state?.jobSite?._id) {
  //   const jobSite = props.location.state.jobSite
  //   console.log('Jobsite', jobSite)
  //   if (jobSite && jobSite.location && jobSite.location.coordinates) {
  //     center = {
  //       lat: jobSite.location.coordinates[1],
  //       lng: jobSite.location.coordinates[0]
  //     }
  //   }
  //   // setJobSite(props.location.state.jobSite)
  // }
 if (jobSite && jobSite.location && jobSite.location.coordinates) {
  center = {
    lat: jobSite.location.coordinates[1],
    lng: jobSite.location.coordinates[0]
  }
 }
  // console.log('Center', center)
  let label = 'Property'

  return (
   <div className={styles.root}>
       <div className={styles.mapContainer}>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GMAPKEY} libraries={libs}>
        
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onZoomChanged={handleZoomChanged}
            onLoad={map => mapRef.current = map}
            options={{
              streetViewControl: true,
              draggable: true,
              zoomControl: true,
              keyboardShortcuts: false,
              scaleControl: true,
              scrollwheel: true,
              mapTypeId: 'satellite'
            }}
          >
            {polygons.map((polygon, index) => {
              // console.log('139 polygon', polygon)
              const area = getPolygonArea(polygon.paths);
              return (
                <React.Fragment key={index}>
                  <Polygon
                    paths={polygon.paths}
                    options={{
                      fillColor: polygon.fillColor,
                      fillOpacity: 0.4,
                      strokeColor: polygon.strokeColor,
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      editable: selectedPolygon === index,
                      draggable: selectedPolygon === index
                    }}
                    onClick={() => handlePolygonClick(index)}
                  />
                  {zoom >= 15 && (
                    <Marker
                      position={getPolygonCenter(polygon.paths)}
                      icon={createLabelIcon(`${polygon.name}: ${area.areaFeet.toFixed(2)} sq ft`)}
                    />
                  )}
                </React.Fragment>
              );
            })}

            <DrawingManagerF
              // ref={drawingManagerRef}
              onPolygonComplete={onPolygonComplete}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  position: 22, // window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: ['polygon']
                },
                polygonOptions: {
                  fillColor: newArea.fillColor,
                  fillOpacity: 0.4,
                  strokeColor: newArea.strokeColor,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  editable: true,
                  draggable: true
                }
              }}
            />
            <Marker position={center} label={label} />
          </GoogleMap>
              

          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>{selectedPolygon !== null ? 'Edit Area' : 'Create New Area'}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="Area Name"
                type="text"
                fullWidth
                value={newArea.name}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="strokeColor"
                label="Outline Color"
                type="color"
                fullWidth
                value={newArea.strokeColor}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="fillColor"
                label="Fill Color"
                type="color"
                fullWidth
                value={newArea.fillColor}
                onChange={handleInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">Cancel</Button>
              {selectedPolygon !== null ? (
                <>
                  <Button onClick={handleDeletePolygon} color="secondary">Delete</Button>
                  <Button onClick={handleUpdatePolygon} color="primary">Update</Button>
                </>
              ) : (
                <Button onClick={handleSaveArea} color="primary">Save</Button>
              )}
            </DialogActions>
          </Dialog>
        </LoadScript>
      </div>
      <div className={styles.sidebarContainer}>
        <Sidebar startDrawing={startDrawingPolygon} polygons={polygons} clickPolygon={handleSidebarClick} selectedPolygonIndex={selectedPolygon} saveAreas={handleSaveAreas} jobSite={jobSite} />
      </div>
          
    </div>
  );
};

const Sidebar = (props) => {
  const styles = useStyles()
  const polygons = props?.polygons || []
  const selectedPolygonIndex = props.selectedPolygonIndex;
  const jobSite = props?.jobSite || null
  const client = jobSite?.client
  const handleClick = (index) => {
    props.clickPolygon(index)
  }

  return (
    <div className={styles.sideBarDetails}>
      {
        (jobSite && jobSite._id) ? (
          <div>
            {
              (client && client._id) ? (
                <div>{client.displayName}</div>
              ) : null
            }
            {jobSite.name} {`${jobSite?.areas?.length ? `(${jobSite.areas.length} Areas Mapped)` : '' }`}
          </div>
        ) : null
      }
      <Button variant="contained" color="primary" onClick={props.startDrawing}>Create New Area</Button>
      <div>
        {
          polygons.map((item, index) => {
            console.log('ITEM 319', item)
            const area = getPolygonArea(item.paths)
            const itemColorBlock = {
              height: 50,
              width: 50,
              borderRadius: 5,
              backgroundColor: item.fillColor,
              border: `2px solid ${item.strokeColor}`
            }
            const itemStyles = `${styles.sidebarItemContainer} ${index === selectedPolygonIndex ? styles.selectedSidebarItem : ''}`;
            return (
              <div key={index} className={itemStyles} onClick={ e => handleClick(index) }>
                {item.name} ({area.areaFeet.toFixed(2)} SF) <div style={itemColorBlock} >&nbsp;</div>
              </div>
            )
          })
        }
      </div>
      <div className={styles.saveArea }>
        {
          polygons?.length ? <Button variant="contained" color="primary" onClick={props.saveAreas}>Save Areas</Button> : null
        }
        
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(MapWithDrawing)

// [, { backgroundColor: item.fillColor, border: `2px solid ${item.strokeColor}` }]
