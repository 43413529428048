import socketIO from 'socket.io-client';

let url = `${process.env.REACT_APP_HOST}`;
console.log('Socket connecting to:', url);

const socket = socketIO(url, { 
  transports: ['websocket'],
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  timeout: 20000 
});

// Track active subscriptions
const activeSubscriptions = new Map();

socket.on('connect', () => {
  console.log('Connected to socket server:', socket.id);
});

socket.on('disconnect', () => {
  console.log('Disconnected from socket server');
});

// Enhanced subscribe function
const subscribe = (channel, callback) => {
  // Convert ObjectId to string if needed
  const channelString = channel.toString();
  
  console.log(`Subscribing to channel: ${channelString}`);
  
  // Clean up existing subscription if any
  if (activeSubscriptions.has(channelString)) {
    socket.off(channelString, activeSubscriptions.get(channelString));
  }
  
  // Create wrapper to handle message format
  const handler = (data) => {
    console.log(`Received message on ${channelString}:`, data);
    callback(data);
  };
  
  // Subscribe to both specific channel and -invoiceList variant
  socket.on(channelString, handler);
  socket.on(`${channelString}-invoiceList`, handler);
  
  // Store for cleanup
  activeSubscriptions.set(channelString, handler);
  
  // Notify server about subscription
  socket.emit('subscribe', channelString);
  
  // Return cleanup function
  return () => {
    socket.off(channelString, handler);
    socket.off(`${channelString}-invoiceList`, handler);
    activeSubscriptions.delete(channelString);
  };
};

// Enhanced send function
const send = (channel, message) => {
  if (!socket.connected) {
    console.warn('Socket not connected, attempting to send to:', channel);
    return;
  }
  
  const channelString = channel.toString();
  console.log(`Emitting to ${channelString}:`, message);
  socket.emit(channelString, message);
};

// Debug logging in development
if (process.env.NODE_ENV === 'development') {
  socket.onAny((event, ...args) => {
    console.log(`Socket Event [${event}]:`, args);
  });
}

export {
  socket,
  subscribe,
  send
};
