import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, IconButton, CircularProgress,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import PlanInputItem from './PlanInputItem'
// import SupplyInventoryInputItem from './SupplyInventoryInputItem'
// import SupplierComponent from './SupplierComponent'
import { Editor } from 'react-draft-wysiwyg';
// import {EditorState} from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { validateEmail } from '../../../services/common'
import AddAttachmentsModal from './AddAttachmentsModal'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import './editorStyles.css'

const StyledEditor = styled.div`
  /* Targeting the figure that wraps the image */
  .rdw-image-alignment, .rdw-image-left {
    margin: 0 !important;
    padding: 0 !important;
    text-align: left; /* Ensuring the image aligns to the left */
  }

  /* Targeting the image wrapper span */
  .rdw-image-imagewrapper {
    display: block; /* Making sure the wrapper behaves as a block */
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Directly targeting the img tag */
  img {
    display: block; /* Ensuring images are block elements */
    margin-left: 0 !important; /* Force image to align to the left */
    padding-left: 0 !important; /* Ensure no padding pushes it away from the left */
    height: auto; /* Adjust height as needed, or remove if setting height directly */
    width: auto; /* Adjust width as needed */
  }

  figure {
    margin-inline-start: 0 !important;
  }
  .demo-editor .public-DraftStyleDefault-block {
    margin: 0; /* Remove margin between blocks */
  }

  .demo-editor p {
    margin-top: 0px;
    margin-bottom: 2px; /* Adjust this value as needed */
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const AttachmentListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  fromEmail: '',
  data: {},
  dataOptions: [],
  counter: 0,
  attachments: [],
  subject: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SendToCustomerModal = (props) => {
  // console.log('Send To Customer Modal:', props)
  const classes = useStyles();

  const [dialogValue, setDialogValue] = React.useState(defaultStateValues);
  const [state, setState] = React.useState(defaultStateValues)
  const [recipientList, setRecipientList] = React.useState([])
  const [item, setItem] = React.useState({})
  const [open, toggleOpen] = React.useState(false);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [content, setContentState] = React.useState({})
  const [checked, setChecked] = React.useState([]);
  const [dataThatCanBeEmailed, setDataThatCanBeEmailed] = React.useState([])
  const [showAddAttachmentsModal, setShowAddAttachmentsModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [ccList, setCCList] = React.useState([])

  const resetOurState = () => {
    setRecipientList([])
    setEditorState(EditorState.createEmpty())
    setContentState({})
    setChecked([])
    setState(defaultStateValues)
    
    handleClose()
  }

  React.useEffect(() => {
    let newState = {
      type: props.type
    }
    if (props.estimate && props.estimate._id) {
      const defaultSubject = `Estimate #${props.estimate.number} - ${props.estimate.name}`
      newState.subject = defaultSubject
    } else if (props.payment && props.payment._id && props.type && props.type === 'Receipt') {
      const defaultSubject = `Receipt For Payment on Invoice #${props.payment?.invoiceNumber}`
      newState.subject = defaultSubject
    }
    setState({...state, ...newState})
  }, [props])

  const fetchClientData = async (clientId) => {
    // console.log('Fetch Data:', clientId)
    const response = await axios.get(`${props.url}/api/customer/getDataThatCanBeEmailed?id=${clientId}`)
    // console.log('Client Data', response)
    if (response.data && response.data.length) {
      // console.log('Set Data options', response.data)
      setDataThatCanBeEmailed(response.data)
      // setState({ ...state, dataOptions: response.data, counter: state.counter++ })
    }
    // let ourDataOptions = [
    //   {
    //     category: 'Proposals',
    //     items: response.data['Proposals']
    //   },
    //   {
    //     category: 'Invoices',
    //     items: response.data['Invoices']
    //   },
    //   {
    //     category: 'Uploads',
    //     items: response.data['Uploads']
    //   }
    // ]
    // console.log('Update our Data Options', ourDataOptions)
   
  }

  // React.useState(() => {
  //   if (props.client && props.client._id && !props.data && !props.pdf) {
  //     console.log('We need to get our options to send shit')
  //     fetchClientData(props.client._id)
  //   }
  // }, [])

  // React.useEffect(() => {
  // console.log('PROPS send to customer changed', props)
  //   // setState({...state, name: props.item.name })
  //   // setDialogValue({...dialogValue, name: props.item.name, description: props.item.name})
  //   // let html = '<h1>Test</h1>'
  //   // setContentState(content)
  //   if (props.user && props.user.emailSignature) {
  //     console.log('Use the user signature!!!', props.user)
  //     const html = props.user.emailSignature
  //     const contentBlock = htmlToDraft(html);
  //     if (contentBlock) {
  //       console.log('231 go')
  //       const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //       const editorState = EditorState.createWithContent(contentState);
  //       setContentState(contentState)
  //       setEditorState(editorState)
  //     }
  //   } else if (props.entity && props.entity._id) {
  //     console.log('Set Signature based on entity...')
  //     const html = `<p></p><p></p><br/><br/><br/>${props.entity.name}<br />${props.entity.billingAddress}<br />${props.entity.billingCity}, ${props.entity.billingState}, ${props.entity.billingZip}<br />${props.entity.telephone}`;
  //     const contentBlock = htmlToDraft(html);
  //     if (contentBlock) {
  //       console.log('231 go')
  //       const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //       const editorState = EditorState.createWithContent(contentState);
  //       setContentState(contentState)
  //       setEditorState(editorState)
  //     }
  //   }   
  // }, [props.user])

  const [tabOpenValue, setTabOpenValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setTabOpenValue(newValue);
  }

  const handleClose = () => {
    setDialogValue(defaultStateValues);

    props.handleCloseModal();
  };

  const handleSend = (event) => {
    event.preventDefault();
  // console.log('Send this shit!!!', recipientList)
  // console.log('props', props)
    setLoading(true)
    let invalidEmailsDetected = false
    let hasAtLeastOneContact = false
    let message = 'Invalid Email:  '
    for (const item of recipientList) {
    // console.log('Recipient', item)
      if (item && item._id) hasAtLeastOneContact = true
      let isValid = validateEmail(item.email)
    // console.log('IS Valid:', isValid)
      if (!isValid) {
        invalidEmailsDetected = true
        message += item.email
      }
    }
    if (invalidEmailsDetected) {
      props.notification({
        title: 'Invalid Emails',
        message: message,
        type: 'warning'
      })
    } else {
      let data = {
        recipientList,
        ccList,
        dataToSend: props.data,
        emailContent: content,
        fromEmail: `${state.fromEmail}`,       
        client: props.client,
        contact: props.contact,
        pdf: props.pdf,
        type: props.type,
        subject: state.subject,
        attachments: state.attachments,
        estimate: props?.estimate,
        payment: props?.payment
      }
    console.log(data)
      axios({
        method: 'post',
        url:  `${props.url}/api/customer/sendSomethingToTheCustomer`,
        data: data,
      }).then(response => {
        console.log("email resp", response)
        console.log('Sent Email: ', response.data)
        if (response && response.data && response.data.status && response.data.status === '200') {
     
          resetOurState()
          
          props.notification({
            type: 'success',
            title: 'Sent!',
            message: `Your ${props.type} has been successfully emailed to the customer!`
          })
          
        }
        if (response && response.data && response.data.error) {
          props.notification({
            type: 'warning',
            title: 'System Error Sending',
            message: response.data.message
          })
        }
  
      }).catch(err => {
        console.log('Error caught when sending someting: ', err)
        props.notification({
          type: 'warning',
          title: 'Failed to Send!',
          message: `Error Trying to Send Email`
        })
      }).finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      })
    }

    // console.log(dialogValue)
    // console.log(props)
    // // console.log(dialogValue)
    // // console.log('Got it all?')
    // handleClose();
  };

  const handleCategoryChange = (e) => {
    setDialogValue({...dialogValue, category: e.target.value})
  }
  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const updatePlantDetails = (d, c) => {
    // console.log('Update Plant Details: ', d)
    // console.log('c: ', c)
    if (c && c.unitsOfMeasure && c.unitsOfMeasure.length) d.unitsOfMeasure = c.unitsOfMeasure
    setState({...state, inputDetails: d})
  }

  // const handleSave = () => {
  //   // console.log('Save this shit!!!', state)
  //   // console.log(dialogValue)
  //   // console.log(props)

  // }

  const contacts = [
    {
      displayName: 'Smith, Tom'
    }
  ]

  const handleContactChange = (e, newItem) => {
    // console.log('Contact change: ', newItem)
    setRecipientList(newItem)
  }

  const senderOptions = () => {
    let options = [
      {
        firstName: 'The',
        lastName: 'Office',
        email: 'Office Email'
      }
    ]
    return options
  }

  const onEditorStateChange = (editorStateChange) => {
    // console.log(editorStateChange)
    setEditorState(editorStateChange)
  }

  const handleBlur = (data) => {
    // console.log(data)
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log(html)
    setContentState(html)
    // props.updateContent(html)
  }
  const onContentStateChange = (contentState) => {
    // console.log(contentState)
    setContentState(contentState)
  }

  const handleVerifyEmail = (e) => {
    let email = e.target.value
    // console.log('Verify Email: ', email)
    let cleanEmail = email
    if (email.includes('@')) {
      cleanEmail = email.split('@')[0]
    }
    // console.log('clean email: ', cleanEmail)
    setState({...state, fromEmail: cleanEmail, emailValidated: true})
  }

  const options = [
    {
      category: 'Fruits',
      items: ['Apple', 'Banana', 'Cherry']
    },
    {
      category: 'Animals',
      items: ['Dog', 'Cat', 'Elephant']
    }
    // ... Add more categories as needed
  ];


  const handleToggle = (value) => () => {
    console.log('Handle toggle ', value)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
  
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
  
    setChecked(newChecked);
  };

  const handleCloseAddAttachmentModal = () => {
    setShowAddAttachmentsModal(false)

  }

  const chooseAttachments = () => {
    setShowAddAttachmentsModal(true)
  }

  const handleAddAttachments = (items) => {
    console.log('ATtach this shit:', items)
    setShowAddAttachmentsModal(false)
    setState({...state, attachments: items })
  }

  const handleDelete = (itemId) => {
    console.log('Deete this one:', itemId)
    let newState = { ...state }
    let index = newState.attachments.findIndex(item => (item._id === itemId))
    if (index > -1) {
      newState.attachments.splice(index, 1)
      setState({...state, ...newState})
    }
  }

  let otherPeopleToInclude = [
    {
      firstName: 'Marc',
      lastName: 'Vargas',
      email: 'marc@hmiofaz.com'
    }
  ]
  let allEmpsToShow = [...props.employees, ...otherPeopleToInclude]
  let employeesWithEmail = allEmpsToShow.filter(emp => (emp && emp.email && emp.email.length))
  employeesWithEmail.sort((a, b) => a.firstName - b.firstName)
  
  let clientId = (props.client && props.client._id) ? props.client._id : ''
  return (
    <div>
      <AddAttachmentsModal open={showAddAttachmentsModal} attachments={state.attachments} clientId={clientId} closeModal={handleCloseAddAttachmentModal} url={props.url} addTheAttachments={handleAddAttachments} />

    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">

      <DialogTitle id="form-dialog-title">Send {props.type} To Customer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Choose Who Should Receive This {props.type} 
        </DialogContentText>


      <LabelText>Choose/Enter The Name of the Receipient(s):</LabelText>
      <FormControl fullWidth >
   
        <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              fullWidth
              options={props.contacts || []}
              // onChange={handleUpdateUOM}
              // onChange={handleContactChange}
              // // disableCloseOnSelect
              value={recipientList}
              // onInputChange={handleInputChange}
              onChange={(e, newValue) => {
              // console.log('EMail change 282', e.target.value)
              // console.log('New Value:', newValue)
                if (typeof newValue === 'string') {
                // console.log('283', newValue)
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                // console.log('INPUTVALUE 286', newValue.inputValue)
                } else {
                // console.log('288 New Value', e.target.value)
                  if (newValue && newValue.length) {
                    let thisOne = newValue[newValue.length -1]
                  // console.log('This ONe!', thisOne)
                    if (thisOne && thisOne.email) {
                      setRecipientList(newValue)
                    } else {
                    // console.log('New Email address', thisOne)
                      // Verify its a legit email
                      let isValidEmail = validateEmail(thisOne)
                    // console.log('Valid email??', isValidEmail)
                      if (isValidEmail) {
                        let newItem = {
                          firstName: thisOne,
                          lastName: '',
                          email: thisOne
                        }
                        let recips = recipientList
                        recips.push(newItem)
                      // console.log('Updated recipient list', recips)
                        setRecipientList(recips)
                      } else {
                        props.notification({
                          type: 'warning',
                          title: 'Invalid Email Address',
                          message: `Sorry, but ${thisOne} is not a valid email address.`
                        })
                      }
                      
                    
                    }
                  } else {
                  // console.log('Empty array')
                    setRecipientList([])
                  }
                }
              }}
              freeSolo
              style={{marginTop: 25}}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                    {option.firstName} {option.lastName} - {option.email}
                </React.Fragment>
              )}
              
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Recipient List" placeholder="Who Should Receive A Copy of This Email?" helperText={`After selecting at least one Contact, you can type in other email addresses. Press ENTER to save the email address (It Should Be In Grey)`} />
              )}
            /> 
      </FormControl>
      <LabelText>Choose/Enter Employees to Be CC'd On This Email:</LabelText>
      <FormControl fullWidth >
      <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              fullWidth
              options={employeesWithEmail || []}
              // onChange={handleUpdateUOM}
              // onChange={handleContactChange}
              // // disableCloseOnSelect
              value={ccList}
              // onInputChange={handleInputChange}
              onChange={(e, newValue) => {
              // console.log('EMail change 282', e.target.value)
              // console.log('New Value:', newValue)
                if (typeof newValue === 'string') {
                // console.log('283', newValue)
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                // console.log('INPUTVALUE 286', newValue.inputValue)
                } else {
                // console.log('288 New Value', e.target.value)
                  if (newValue && newValue.length) {
                    let thisOne = newValue[newValue.length -1]
                  // console.log('This ONe!', thisOne)
                    if (thisOne && thisOne.email) {
                      setCCList(newValue)
                    } else {
                    // console.log('New Email address', thisOne)
                      // Verify its a legit email
                      let isValidEmail = validateEmail(thisOne)
                    // console.log('Valid email??', isValidEmail)
                      if (isValidEmail) {
                        let newItem = {
                          firstName: thisOne,
                          lastName: '',
                          email: thisOne
                        }
                        let recips = ccList
                        recips.push(newItem)
                      // console.log('Updated recipient list', recips)
                        setCCList(recips)
                      } else {
                        props.notification({
                          type: 'warning',
                          title: 'Invalid Email Address',
                          message: `Sorry, but ${thisOne} is not a valid email address.`
                        })
                      }
                      
                    
                    }
                  } else {
                  // console.log('Empty array')
                    setCCList([])
                  }
                }
              }}
              
              style={{marginTop: 25}}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                    {option.firstName} {option.lastName} - {option.email}
                </React.Fragment>
              )}
              
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Employee CC List" placeholder="Employees Who Should Receive A Copy of This Email?" helperText={`If you don't see someone in this list, ensure they have an email address in the portal.`} />
              )}
            /> 
      </FormControl>
      <LabelText>Choose/Enter The From Email Address:</LabelText>
      <FormControl fullWidth >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
              variant="outlined"
              label="From Email Address"
              fullWidth
              select
              value={state.fromEmail}
              onChange={e=>setState({...state, fromEmail: e.target.value})}
              // onBlur={handleVerifyEmail}
            >
              {
                (props.user && props.user.email) ? (
                  <MenuItem value={`${props.user.email}`}>
                    Your Email ({`${props.user.email}`})
                  </MenuItem>
                ) : null
              }
             
              <MenuItem value={`office@dflaz.com`}>
                  DFL Email (office@dflaz.com)
              </MenuItem>
              <MenuItem value={`info@benhartlandscaping.com`}>
                  Benhart Email (info@benhartlandscaping.com)
              </MenuItem>
              <MenuItem value={`office@hmiofaz.com`}>
                  HMI Email (office@hmiofaz.com)
              </MenuItem>

              
              </TextField>
          </Grid>
          {/* <Grid item lg={3} xs={12} style={{fontSize: 24, textAlign: 'left', marginTop: 5}}>
            @{props.entity.domain}
          </Grid> */}
        </Grid>
      </FormControl>
      { (recipientList.length && state.fromEmail) ? (
        <FormControl fullWidth >
        <LabelText>Enter The Subject Line:</LabelText>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
                variant="outlined"
                label="Subject"
                fullWidth
                type="text"
                value={state.subject}
                onChange={e=>setState({...state, subject: e.target.value})}
                // onBlur={handleVerifyEmail}
              />
              </Grid>
          </FormControl>
        ) : null
      }
      { (recipientList.length && state.fromEmail) ? (

        <div>
          {
            state.type === 'Receipt' ? null : (
            <FormControl fullWidth >
            <LabelText>Enter The Message You'd Like Them To Receive In The Email:</LabelText>
            <div style={{ border: '3px solid gray', marginTop: 10, marginBottom: 20, minHeight: 400}} >
              <StyledEditor>
                <Editor
                  // initialContentState={content}
                  editorState={editorState}
                  spellCheck={true}
                  wrapperClassName="demo-wrapper"
                  // editorClassName="demo-editor"
                  editorClassName="myEditorClassName"
                  onEditorStateChange={onEditorStateChange}
                  // onContentStateChange={onContentStateChange}
                  onBlur={handleBlur}
                />
              </StyledEditor>
            {/* <textarea
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
              /> */}
              {
                props.user.emailSignature ? (
                  <div dangerouslySetInnerHTML={{ __html: props.user.emailSignature }} />
                ) : ''
              }
            </div>
      
            <LabelText>Attachment(s):</LabelText>
            <FormControl fullWidth >
            <Grid container >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button onClick={chooseAttachments} variant="contained" color="primary" startIcon={<AttachFileIcon />}>Add Attachment</Button>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}  style={{ maxHeight: 400, overflowY: 'scroll' }}>
                {
                  state.attachments.map((item, index) => {
                    return (
                      <AttachmentListContainer key={index}>
                          <div>{item.description}</div>
                            <div>
                              <DeleteButton itemId={item._id} handleClick={handleDelete} />
                            </div>
                      </AttachmentListContainer>
                    
                    )
                  })
                }
              </Grid>
              {/* <Grid item lg={3} xs={12} style={{fontSize: 24, textAlign: 'left', marginTop: 5}}>
                @{props.entity.domain}
              </Grid> */}
            </Grid>
          </FormControl>
          </FormControl>
          )
        }
      </div>
      )
       : null }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSend}
          color="primary"
          disabled={loading || !state.fromEmail || (!recipientList || recipientList.length < 1)}
        >
          {
          loading ? (
            <CircularProgress size={16} />
          ) : 'Send' }
        </Button>
      </DialogActions>
  
   </Dialog>
   </div>
  )
}

const DeleteButton = (props) => {
  // console.log('Delete button props:', props)
    
    const handleClick = () => {
      props.handleClick(props.itemId)
    }
  
    return (
      <Tooltip
        title="Delete">
          <IconButton
            color={props.color}
            onClick={handleClick}
          ><DeleteIcon /></IconButton>
        </Tooltip>
    )
  }

const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.user,
    contacts: state.contacts,
    entity: state.entity,
    employees: state.employees
  }
}

export default connect(mapStateToProps)(SendToCustomerModal)
